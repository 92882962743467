<template>
    <div class="container">
        <div class="search-wrap">
            <Form ref="calcForm" :label-width="80" inline>
                <FormItem label="年度">
                    <Select v-model="calcForm.year" style="width:200px">
                        <Option v-for="item in yearArr" :value="item" :label="item"></Option>
                    </Select>
                </FormItem>
                <FormItem label="区间">
                    <Select v-model="calcForm.interval" style="width:200px">
                        <Option v-for="item in intervalArr" :value="item" :label="item"></Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="handleSubmit('calcForm')">计算</Button>
                    <Button style="margin-left: 20px;" type="primary" @click="downloadKpi"><Icon type="md-download"></Icon>下载报表</Button>
                    <!-- <Button style="margin-left: 20px;" type="primary" @click="downloadRegionReceived"><Icon type="md-download"></Icon>下载区域回款报表</Button> -->
                </FormItem>
            </Form>
        </div>
        <Divider>营销区域经理绩效</Divider>
        <div class="table-wrap">
            <Table :columns="columns1" :data="data1" stripe border :loading="loading" >
                <template #actualSignNum="{ row, index }">
                    <!-- <strong class="link-txt" @click="openModal('sale', row.region)">{{ row.actualSignNum }}</strong> -->
                    <div>{{ row.actualSignNum }}</div>
                </template>
            </Table>
        </div>
        <Divider>工程部经理绩效</Divider>
        <div class="table-wrap">
            <Table :columns="columns2" :data="data2" stripe border :loading="loading" >
                <template #securityIncidentNum="{ row }">
                    <strong style="color: #ed4014;" v-if="row.securityIncidentNum > 0">{{ row.securityIncidentNum }}</strong>
                    <div v-else>{{ row.securityIncidentNum }}</div>
                </template>
                <template #totalScore="{ row }">
                    <span>{{ row.totalScore }}</span>
                    <span style="color: #ed4014;" v-if="row.securityIncidentNum > 0">（存在安全事故）</span>
                </template>
                <!-- <template #operate="{ row }">
                    <Button type="primary" @click="openModal('engineer')">工期明细</Button>
                </template> -->
            </Table>
        </div>
        <Divider>安全部经理绩效</Divider>
        <div class="table-wrap">
            <Table :columns="columns3" :data="data3" stripe border :loading="loading" >
                <template #securityIncidentNum="{ row }">
                    <strong style="color: #ed4014;" v-if="row.securityIncidentNum > 0">{{ row.securityIncidentNum }}</strong>
                    <div v-else>{{ row.securityIncidentNum }}</div>
                </template>
                <template #totalScore="{ row }">
                    <span>{{ row.totalScore }}</span>
                    <span style="color: #ed4014;" v-if="row.securityIncidentNum > 0">（存在安全事故）</span>
                </template>
            </Table>
        </div>
        <Divider>设计部经理绩效</Divider>
        <div class="table-wrap">
            <Table :columns="columns4" :data="data4" stripe border :loading="loading" ></Table>
        </div>
        <Divider>采购成本部经理绩效</Divider>
        <div class="table-wrap">
            <Table :columns="columns5" :data="data5" stripe border :loading="loading" ></Table>
        </div>
        <Divider>资料部经理绩效</Divider>
        <div class="table-wrap">
            <Table :columns="columns6" :data="data6" stripe border :loading="loading" ></Table>
        </div>
        <Divider>前期配套部经理绩效</Divider>
        <div class="table-wrap">
            <Table :columns="columns7" :data="data7" stripe border :loading="loading" ></Table>
        </div>

        <Modal
            v-model="detailModal"
            :title="ModalTitle"
            footer-hide
            fullscreen
            scrollable
            >
            <Table :columns="detailColumns" :data="detailData" stripe border :loading="detailLoading" ></Table>
        </Modal>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                loading: false,
                yearArr: [2022, 2023, 2024],
                intervalArr: ['上半年', '下半年'],
                calcForm: {
                    year: '',
                    interval: '上半年'
                },
                detailModal: false,
                ModalTitle: '',
                columns1: [
                    {
                        title: '所属区域',
                        key: 'region',
                    },
                    {
                        title: '实际签约数',
                        key: 'actualSignNum',
                        slot: 'actualSignNum'
                    },
                    {
                        title: '预期签约数',
                        key: 'expectedSignNum',
                    },
                    {
                        title: '合同签约指标计分',
                        key: 'contractCompletedScore',
                    },
                    {
                        title: '实际回款总金额',
                        key: 'actualReceived',
                    },
                    {
                        title: '应收回款总金额',
                        key: 'expectedReceived',
                    },
                    {
                        title: '应收回款指标计分',
                        key: 'receivedCompletedScore',
                    },
                    {
                        title: '资料收集及时完整性计分',
                        key: 'dataCollectionCompletedScore',
                    },
                    {
                        title: '培训计划完成打分',
                        key: 'trainingScoreSales',
                    },
                    {
                        title: '平均奖金系数',
                        key: 'averageBonusFactor',
                    },
                    {
                        title: '合同质量指标计分',
                        key: 'contractsQualityScore',
                    },
                    {
                        title: '总分',
                        key: 'totalScore',
                    },
                ],
                data1: [],
                columns2: [
                    {
                        title: '工程完工指标计分',
                        key: 'engineeringCompletedScore',
                    },
                    {
                        title: '施工周期计分',
                        key: 'constructionPeriodScore',
                    },
                    {
                        title: '培训计划完成打分',
                        key: 'trainingScoreEngineer',
                    },
                    {
                        title: '施工管理计分',
                        key: 'constructionManageScore',
                    },
                    {
                        title: '安全事故数',
                        key: 'securityIncidentNum',
                        slot: 'securityIncidentNum'
                    },
                    {
                        title: '总分',
                        key: 'totalScore',
                        slot: 'totalScore'
                    },
                    // {
                    //     title: '操作',
                    //     slot: 'operate',
                    //     align: 'center'
                    // }
                ],
                data2: [],
                columns3: [
                    {
                        title: '安全检查计分',
                        key: 'safeCheckScore',
                    },
                    {
                        title: '安全整改计分',
                        key: 'safeRectificationScore',
                    },
                    {
                        title: '安全培训计分',
                        key: 'trainingScoreSecurity',
                    },
                    {
                        title: '投诉处理计分',
                        key: 'complaintHandlingScore',
                    },
                    {
                        title: '安全事故数',
                        key: 'securityIncidentNum',
                        slot: 'securityIncidentNum'
                    },
                    {
                        title: '总分',
                        key: 'totalScore',
                        slot: 'totalScore'
                    }
                ],
                data3: [],
                columns4: [
                    {
                        title: '设计周期完成指标计分',
                        key: 'designCompletedScore',
                    },
                    {
                        title: '成本优化打分',
                        key: 'costOptimizeScoreDesign',
                    },
                    {
                        title: '图纸审校打分',
                        key: 'drawingReviewScore',
                    },
                    {
                        title: '高效服务打分',
                        key: 'efficientServiceScoreDesign',
                    },
                    {
                        title: '培训计划完成打分',
                        key: 'trainingScoreDesign',
                    },
                    {
                        title: '总分',
                        key: 'totalScore',
                    }
                ],
                data4: [],
                columns5: [
                    {
                        title: '采购准时发货率打分',
                        key: 'purchasingDeliveryScore',
                    },
                    {
                        title: '采购成本控制打分',
                        key: 'purchasingCostScore',
                    },
                    {
                        title: '报价准时完成率打分',
                        key: 'quotationCompletedScore',
                    },
                    {
                        title: '决算报告及时完成率计分',
                        key: 'finalReportCompletedScore',
                    },
                    {
                        title: '总分',
                        key: 'totalScore',
                    },
                ],
                data5: [],
                columns6: [
                    {
                        title: '报规工作及时完成率计分',
                        key: 'reportingPlanningScore',
                    },
                    {
                        title: '报审工作及时完成率计分',
                        key: 'reportingConstructionScore',
                    },
                    {
                        title: '竣工资料的及时完成率计分',
                        key: 'completedDataFinishScore',
                    },
                    {
                        title: '审价报告、补贴资料的及时完成率计分',
                        key: 'reviewAndSubsidyDataFinishScore',
                    },
                    {
                        title: '总分',
                        key: 'totalScore',
                    },
                ],
                data6: [],
                columns7: [
                    {
                        title: '管线迁移及时完成率计分',
                        key: 'pipelineTransferCompletedScore',
                    },
                    {
                        title: '成本优化打分',
                        key: 'costOptimizeScoreSupport',
                    },
                    {
                        title: '高效服务打分',
                        key: 'efficientServiceScoreSupport',
                    },
                    {
                        title: '总分',
                        key: 'totalScore',
                    },
                ],
                data7: [],
                detailLoading: false,
                detailData: [],
                detailColumns: []
            }
        },
        methods:{
            loadPage(calcForm){
                this.loading = true;
                this.$http.get("/dashboard/kpiViewList", calcForm).then(rs=>{
                    this.data1 = rs.saleManagerKpis;
                    this.data2 = rs.engineeringManagerKpis;
                    this.data3 = rs.securityManagerKpis;
                    this.data4 = rs.designManagerKpis;
                    this.data5 = rs.purchaseManagerKpis;
                    this.data6 = rs.dataManagerKpis;
                    this.data7 = rs.supportManagerKpis;
                    this.loading = false;
                }).catch(err=>{
                    this.loading = false;
                })
            },
            handleSubmit(name){
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        this.loadPage(this.calcForm);
                    } else {
                        this.$Message.error('Fail!');
                    }
                })
            },
            openModal(type, region) {
                if (type === 'sale') {
                    this.detailColumns = [
                        {
                            title: '项目编号',
                            key: 'projectShortName',
                        },
                        {
                            title: '项目名称',
                            key: 'projectName',
                        },
                        {
                            title: '销售',
                            key: 'saleName',
                        },
                        {
                            title: '合同编号',
                            key: 'contractCode',
                        },
                        {
                            title: '合同金额',
                            key: 'contractAmount',
                        },
                        {
                            title: '生效日期',
                            key: 'activeDate',
                        },
                        {
                            title: '奖金系数',
                            key: 'bonusFactor',
                        }
                    ];
                    this.ModalTitle = region + '-项目明细';
                    this.detailModal = true;
                    this.detailLoading = true;
                    this.$http.get("/dashboard/regionSigned", { ...this.calcForm, region: region }).then(rs=>{
                        this.detailData = rs;
                        this.detailLoading = false;
                    }).catch(err=>{
                        this.detailLoading = false;
                    })
                } else if (type === 'engineer') {
                    this.detailColumns = [
                        {
                            title: '项目编号',
                            key: 'projectShortName',
                        },
                        {
                            title: '项目名称',
                            key: 'projectName',
                        },
                        {
                            title: '整体移交日期',
                            key: 'handoverDate',
                        },
                        {
                            title: '总工期',
                            key: 'totalDuration',
                        },
                    ];
                    this.ModalTitle = '项目施工明细';
                    this.detailModal = true;
                    this.detailLoading = true;
                    this.$http.get("/dashboard/constructionDetail", this.calcForm).then(rs=>{
                        this.detailData = rs;
                        this.detailLoading = false;
                    }).catch(err=>{
                        this.detailLoading = false;
                    })
                }
                
            },
            downloadKpi() {
                this.$Modal.confirm({
                    title: '提示',
                    content: `是否下载绩效管理总览表？`,
                    okText: '立即下载',
                    onOk: () => {
                        this.$Message.info('开始下载...');
                        this.$http.exportExcel(`/dashboard/exportKpi`, this.calcForm).then(rs=>{
                            this.$common.exportBillingExcel(rs.data, rs)
                        })
                    },
                })
            },
            downloadRegionReceived() {
                this.$Modal.confirm({
                    title: '提示',
                    content: `是否下载区域回款总览表？`,
                    okText: '立即下载',
                    onOk: () => {
                        this.$Message.info('开始下载...');
                        this.$http.exportExcel(`/dashboard/exportRegionReceived`, this.calcForm).then(rs=>{
                            this.$common.exportBillingExcel(rs.data, rs)
                        })
                    },
                })
            }
        },
        mounted() {
            this.calcForm.year = new Date().getFullYear();
            this.loadPage(this.calcForm);
        },
    }
</script>

<style scoped>
    .container{
		width: 100%;
        height: 100%;
		margin: 0 auto;
		padding: 24px 15px; 
		background: #fff;
	}
    .search-wrap{
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }
    .search-wrap .inp{
        width: 400px;
    }
    .table-wrap .page-box{
        margin-top: 20px;
        text-align: right;
    }

    .link-txt{
        color: #2d8cf0;
        text-decoration: underline;
        cursor: pointer;
    }

</style>